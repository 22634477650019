import { AddIcon, DeleteIcon, EditIcon, Icon } from '@sg/icons';
import { Button, Card, Content, DataList, DataListForComponent, dateToLocaleString, Group, translateEnum, useDataList, useTranslate } from '@sg/react';
import { useMemo, useState } from 'react';
import { AuthUserStatus } from '../../models/auth.model';
import GroupFormModal from './components/GroupFormModal';
import UserFormModal from './components/UserFormModal';

export interface UserForList {
    _id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    status: AuthUserStatus;
    connectedAt?: number;
}

const Users = () => {
    const translate = useTranslate();
    const users = useDataList<UserForList>('/users');
    const [userToEdit, setUserToEdit] = useState<Partial<UserForList>>();
    const groups = useDataList<Group>('/groups');
    const [groupToEdit, setGroupToEdit] = useState<Partial<Group>>();

    const status = useMemo(() => translateEnum(AuthUserStatus, 'users', 'status', translate), []);

    return (
        <Content title={{ namespace: 'users', key: 'users' }} row className="gap-layout">
            <Card className="width-70 padding-none">
                <DataList<UserForList>
                    {...users}
                    primaryKey="_id"
                    columns={[
                        { field: '_id', label: 'ID' },
                        { field: 'email', label: { namespace: 'portal', key: 'email' }, sortable: true },
                        { field: 'firstName', label: { namespace: 'users', key: 'firstName' }, sortable: true },
                        { field: 'lastName', label: { namespace: 'users', key: 'lastName' }, sortable: true },
                        { field: 'status', label: { namespace: 'entities', key: 'status' }, sortable: true, display: (e) => status.find(s => s.key === e.status)?.label ?? '' },
                        { field: 'connectedAt', label: { namespace: 'users', key: 'connectedAt' }, sortable: true, display: (e) => e.connectedAt ? dateToLocaleString(e.connectedAt) : '' },
                    ]}
                    actions={[
                        { key: 'delete', icon: DeleteIcon, onClick: console.log },
                        { key: 'edit', icon: EditIcon, onClick: setUserToEdit },
                    ]}
                >
                    <Button color="secondary" icon={AddIcon} label={{ namespace: 'actions', key: 'create' }} onClick={() => setUserToEdit({})} />
                </DataList>
            </Card>
            <Card title="users.groups" className="width-30">
                <span className="text-small">{translate('users.groups_tooltip')}</span>
                <DataListForComponent<Group>
                    {...groups}
                    actions={[
                        { key: 'add', icon: AddIcon, onClick: () => setGroupToEdit({}) }
                    ]}
                    options={{
                        sort: {
                            name: { namespace: 'entities', key: 'name' }
                        },
                    }}
                >
                    {groups.data?.map(group => (
                        <div key={group._id} className="list-item interactive width-100 overflow-hidden row align-center gap-large padding-v-small padding-h-medium">
                            <div className="item overflow-hidden grow-1">
                                <div>
                                    <span>{group.name}</span>
                                    {!!group.description && <span>{group.description}</span>}
                                </div>
                            </div>
                            <Icon icon={EditIcon} className="icon-button" onClick={() => setGroupToEdit(group)} />
                        </div>
                    ))}
                </DataListForComponent>
            </Card>
            {!!userToEdit && <UserFormModal user={userToEdit} onClose={() => setUserToEdit(undefined)} />}
            {!!groupToEdit && <GroupFormModal group={groupToEdit} onSubmit={() => groups.refresh()} onClose={() => setGroupToEdit(undefined)} />}
        </Content>
    );
}
export default Users;