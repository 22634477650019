import { AddIcon, CheckmarkIcon, Icon, ImageProhibitedIcon } from "@sg/icons";
import { Button, DataListForComponent, dateToLocaleString, FormControls, FormProvider, InputConnected, Modal, ObjectValidation, Role, TextAreaConnected, useAuth, useDataList, useForm, useTranslate, Validation } from "@sg/react";
import { useState } from "react";
import { News } from "../../../models/news.model";
import { requestHandler } from "../../../services/request";

const NEWS_VALIDATION = new ObjectValidation<News>({
    title: Validation.string().required().minLength(15).maxLength(150),
    subtitle: Validation.string().minLength(20).maxLength(250),
    content: Validation.string().minLength(100),
});

interface HomeNewsFormProps {
    news: Partial<News>;
    onClose: () => void;
    onSubmit: (news: News) => void;
}

const HomeNewsForm = ({ news, onClose, onSubmit }: HomeNewsFormProps) => {
    const form = useForm<News>({
        initial: news,
        validation: NEWS_VALIDATION,
        onSubmit: (news) => (news._id ? requestHandler.put : requestHandler.post)('/news', news, { loader: true, successMessage: true, errorMessage: true })
            .then(() => onSubmit(news))
    });

    return (
        <Modal
            size="medium"
            title={{ namespace: 'actions', key: !news._id ? 'create' : 'edit' }}
            onClose={onClose}
        >
            <FormProvider {...form} className="flex column gap-layout">
                <FormControls />
                <div className="flex row gap-medium">
                    <div className="flex column gap-medium width-100">
                        <InputConnected id="title" namespace="news" />
                        <InputConnected id="subtitle" namespace="news" />
                        <TextAreaConnected rows={20} id="content" namespace="news" />
                    </div>
                </div>
                <div className="button-list">
                    <Button label={{ namespace: 'actions', key: 'cancel' }} color="secondary" onClick={onClose} />
                    <Button label={{ namespace: 'actions', key: 'submit' }} icon={CheckmarkIcon} type="submit" />
                </div>
            </FormProvider>
        </Modal>
    )
}


const HomeNewsList = () => {
    const translate = useTranslate();
    const { currentRole } = useAuth();
    const data = useDataList<News>('/news');
    const [newsToEdit, setNewsToEdit] = useState<Partial<News>>();

    return (
        <div id="home-news-list" className="card width-25 shrink-0">
            <div className="card-header justify-space-between">
                {translate('home.news')}
                {currentRole !== Role.User && <Icon icon={AddIcon} className="icon-button" onClick={() => setNewsToEdit({})} />}
            </div>
            <DataListForComponent<News>
                {...data}
                options={{
                    sort: {
                        createdAt: { namespace: 'entities', key: 'createdAt' }
                    },
                }}
            >
                {data.data?.map(d => (
                    <div key={d._id} className="home-news-list-item width-100 overflow-hidden flex row align-center gap-large padding-v-small padding-h-medium">
                        <div className="soft-container align-center justify-center padding-xxs shrink-0">
                            <Icon icon={ImageProhibitedIcon} className="icon-large" />
                        </div>
                        <div className="flex column grow-1 overflow-hidden">
                            <span className="text-bolder">{d.title}</span>
                            {!!d.subtitle && <span className="text-ellipsis">{d.subtitle}</span>}
                            <div className="padding-top-xxs">
                                {d.createdBy?.fullName ?? d._id} - {dateToLocaleString(new Date(d.createdAt))}
                            </div>
                        </div>
                    </div>
                ))}
            </DataListForComponent>
            {!!newsToEdit && <HomeNewsForm news={newsToEdit} onClose={() => setNewsToEdit(undefined)} onSubmit={() => data.refresh()} />}
        </div>
    );
}
export default HomeNewsList;