import { Button, FormProvider, InputConnected, useForm, useTranslate } from '@sg/react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { EMAIL_VALIDATION, EmailDto } from '../../../models/auth.model';
import { requestHandler } from '../../../services/request';

const ConfirmResend = () => {
    const translate = useTranslate();
    const [success, setSuccess] = useState<boolean>(false);
    const form = useForm<EmailDto>({
        onSubmit: (dto) => requestHandler
            .post('/auth/confirm-resend', dto, { loader: true })
            .then(() => setSuccess(true)),
        validation: EMAIL_VALIDATION
    });

    return (
        <Fragment>
            <div id="portal-title">
                <span>{translate('portal.confirm_resend')}</span>
                <span>{translate('portal.confirm_resend_tooltip')}</span>
            </div>
            {!success ? (
                <FormProvider {...form}>
                    <div className="flex column gap-large">
                        <InputConnected id="email" label={{ key: 'email', namespace: 'portal' }} />
                    </div>
                    <div className="flex column align-center gap-xl padding-v-large">
                        <Button color="accent" className="width-100" label={{ namespace: 'actions', key: 'submit' }} type="submit" />
                        <Link to="/" className="center" >{translate('portal.back_to_login')}</Link>
                    </div>
                </FormProvider>
            ) : (
                <div className="flex column align-center gap-xl padding-v-large">
                    <span className="color-success">{translate('portal.confirm_resend_handled')}</span>
                    <Link to="/" className="center" >{translate('portal.back_to_login')}</Link>
                </div>
            )}
        </Fragment>
    )
}
export default ConfirmResend;