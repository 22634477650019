import { AddIcon, EditIcon } from '@sg/icons';
import { Button, Content, DataList, useDataList, Workspace } from '@sg/react';
import { useNavigate } from 'react-router-dom';

const Workspaces = () => {
    const data = useDataList<Workspace>('/workspaces');
    const navigate = useNavigate();

    return (
        <Content title={{ namespace: 'users', key: 'users' }} noPadding>
            <DataList<Workspace>
                {...data}
                primaryKey="_id"
                withSearch
                columns={[
                    { field: 'name', label: { namespace: 'entities', key: 'name' } },
                    { field: 'description', label: { namespace: 'entities', key: 'description' } },
                ]}
                actions={[
                    { key: 'edit', icon: EditIcon, onClick: (e) => navigate(`/workspaces/${e._id}`) },
                ]}
            >
                <Button color="secondary" icon={AddIcon} label={{ namespace: 'actions', key: 'create' }} onClick={() => navigate('/workspaces/create')} />
            </DataList>
        </Content>
    );
}
export default Workspaces;