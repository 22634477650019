import { CheckmarkIcon } from "@sg/icons";
import { Button, FormProvider, Group, InputConnected, Modal, ObjectValidation, TextAreaConnected, useForm, Validation } from "@sg/react";
import { requestHandler } from "../../../services/request";

const VALIDATION = new ObjectValidation<Group>({
    _id: Validation.string().isMongoId(),
    name: Validation.string().required().minLength(3).maxLength(150),
    description: Validation.string().minLength(20).maxLength(250),
});

interface GroupFormModalProps {
    group: Partial<Group>;
    onClose: () => void;
    onSubmit: () => void;
}

const GroupFormModal = ({ group, onSubmit, onClose }: GroupFormModalProps) => {
    const form = useForm<Group>({
        initial: group,
        onSubmit: (dto) => (dto._id ? requestHandler.put : requestHandler.post)('/groups/', dto, { successMessage: true, errorMessage: true, loader: true })
            .then(() => {
                onSubmit();
                onClose();
            }),
        validation: VALIDATION,
    });

    return (
        <Modal size="xs" onClose={onClose} title={form.formData._id ? form.formData.name : 'users.group_create'}>
            <FormProvider {...form} className="flex column gap-large grow-1">
                <div className="form-group column grow-1">
                    <InputConnected id="name" namespace="entities" />
                    <TextAreaConnected rows={5} id="description" namespace="entities" />
                </div>
                <div className="button-list button-list-stretch">
                    <Button label={{ namespace: 'actions', key: 'cancel' }} color="secondary" onClick={onClose} />
                    <Button label={{ namespace: 'actions', key: 'submit' }} icon={CheckmarkIcon} type="submit" />
                </div>
            </FormProvider>
        </Modal>
    )
}
export default GroupFormModal;