import { ObjectValidation, Validation } from "@sg/react";

export interface UserSettings {
    language?: string;
    theme?: string;
}

export interface UserAuthData {
    password: {
        date: Date;
        changeIn: number;
    };
    twoFactor: TwoFactorMethod;
    recovery: {
        email?: string;
        status: string;
    };
}

export enum TwoFactorMethod {
    QrCode = "QRCODE",
    Email = "EMAIL",
}

export const PROFILE_VALIDATION = new ObjectValidation({
    firstName: Validation.string().required().minLength(3).maxLength(150),
    lastName: Validation.string().required().minLength(3).maxLength(150),
    avatar: Validation.string(),
    company: Validation.string().required().minLength(3).maxLength(150),
    field: Validation.string().required().minLength(3).maxLength(150),
    service: Validation.string().required().minLength(3).maxLength(150),
    position: Validation.string().required().minLength(3).maxLength(150),
});
