import { CheckmarkCircleIcon, Icon } from '@sg/icons';
import { ButtonSubmit, FormProvider, PasswordConnected, requestErrorToToast, useForm, useTranslate, withVerifyIdentity } from '@sg/react';
import { useState } from 'react';
import { AuthDataProps } from '..';
import { NewPassword, PASSWORD_VALIDATION } from '../../../models/auth.model';
import { requestHandler } from '../../../services/request';


const AccountPassword = ({ onSubmit }: Pick<AuthDataProps, 'onSubmit'>) => {
    const translate = useTranslate();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<boolean>(false);
    const form = useForm<NewPassword>({
        onSubmit: (dto) => {
            if (dto.password !== dto.passwordVerify) {
                setError('validation:PASSWORD_MATCH');
                return;
            }

            requestHandler
                .patch('/auth/new-password', dto, { loader: true })
                .then(() => {
                    setSuccess(true);
                    onSubmit();
                })
                .catch((e) => {
                    const error = requestErrorToToast(e as Error);
                    setError(error.message);
                })
        },
        validation: PASSWORD_VALIDATION
    });

    if (success) return (
        <div className="column gap-medium padding-layout align-center justify-center">
            <Icon icon={CheckmarkCircleIcon} className="icon-success icon-xl" />
            <span>{translate('account.password_set')}</span>
        </div>
    )

    return (
        <FormProvider {...form} className="soft-container form-group column">
            <span className="text-small">{translate('account.password_tooltip')}</span>
            {!!error && <span className="color-error">{translate(error)}</span>}
            <PasswordConnected id="password" label={{ key: 'password', namespace: 'portal' }} progressBar />
            <PasswordConnected id="passwordVerify" label={{ key: 'password_verify', namespace: 'portal' }} />
            <div className="button-list">
                <ButtonSubmit type="submit" />
            </div>
        </FormProvider>
    )
}

export default withVerifyIdentity(AccountPassword);