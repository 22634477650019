import { DismissIcon } from '@sg/icons';
import { Button, Modal, Switch, translateEnum, useTranslate, withVerifyIdentity } from '@sg/react';
import { useEffect, useMemo, useState } from 'react';
import { AuthDataProps } from '..';
import { TwoFactorMethod } from '../../../models/user.model';
import { requestHandler } from '../../../services/request';

interface AccountQrCodeProps {
    generate?: boolean;
    onClose: () => void;
}

const AccountQrCode = ({ generate, onClose }: AccountQrCodeProps) => {
    const [qrCode, setQrCode] = useState<string>();

    useEffect(() => {
        requestHandler.get<{ qrCode: string }>(`/auth/qr-code/${generate ? 'generate' : ''}`, { errorMessage: true })
            .then((data) => setQrCode(data.qrCode))
            .catch(() => onClose());
    }, []);

    if (!qrCode) return null;

    return (
        <Modal size="xs">
            <img src={qrCode} alt="" className="width-100" />
            <div className="button-list">
                <Button color="accent" label="actions.close" icon={DismissIcon} onClick={onClose} />
            </div>
        </Modal>
    );
}

const AccountTwoFactor = ({ authData, onSubmit }: AuthDataProps) => {
    const translate = useTranslate();
    const [showQrCode, setShowQrCode] = useState<string>();

    const handleChange = (method: TwoFactorMethod) => requestHandler.patch('/auth/two-factor-method', { method }, { errorMessage: true })
        .then(() => onSubmit())
        .catch(() => null)

    const methods = useMemo(() => translateEnum(TwoFactorMethod, 'account', 'two_factor_method', translate), []);

    return (
        <div className="soft-container form-group column">
            <span className="text-small">{translate('account.two_factor_tooltip')}</span>
            <Switch id="method" value={authData.twoFactor} onChange={(method) => !!method ? handleChange(method) : null} items={methods} label={{ key: 'two_factor_method', namespace: 'account' }} />
            {authData.twoFactor === TwoFactorMethod.QrCode && (
                <div className="column gap-small align-end">
                    <span className="link" onClick={() => setShowQrCode('show')}>{translate('account.two_factor_get_qr_code')}</span>
                    <span className="link" onClick={() => setShowQrCode('generate')}>{translate('account.two_factor_generate_qr_code')}</span>
                </div>
            )}
            {!!showQrCode && <AccountQrCode generate={showQrCode === 'generate'} onClose={() => setShowQrCode(undefined)} />}
        </div>
    )
}

export default withVerifyIdentity(AccountTwoFactor);