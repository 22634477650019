import { configureStore } from "@reduxjs/toolkit";
import { auth, config, setConfig, translation, ui } from "@sg/react";

const store = configureStore({
    reducer: {
        auth,
        ui,
        config,
        translation,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

store.dispatch(
    setConfig({
        appName: "portal",
        instanceName: process.env.REACT_APP_INSTANCE_NAME ?? "Supply-Graph",
        apiUrl: process.env.REACT_APP_API_URL ?? "http://localhost:3005",
        basename: process.env.PUBLIC_URL,
        env: {
            controlTowerUrl: process.env.REACT_APP_CONTROL_TOWER_URL,
            factoryUrl: process.env.REACT_APP_FACTORY_URL,
            npiUrl: process.env.REACT_APP_NPI_URL,
        },
    })
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
