import { ObjectValidation, Role, Validation } from "@sg/react";

export interface LoginDto {
    email: string;
    password: string;
}

export interface LoginTwoFactorDto extends TokenDto {
    code: string;
}

export interface TokenDto {
    token: string;
}

export interface ConfirmSignupDto extends TokenDto {
    password: string;
    passwordVerify: string;
}

export interface NewPassword {
    password: string;
    passwordVerify: string;
}

export const PASSWORD_VALIDATION = new ObjectValidation({
    password: Validation.string()
        .required()
        .regex(/.{8,}/, "PASSWORD_LENGTH")
        .regex(/[A-Z]/, "PASSWORD_LOWERCASE")
        .regex(/[a-z]/, "PASSWORD_UPPERCASE")
        .regex(/[0-9]/, "PASSWORD_DIGIT")
        .regex(/[#?!@$%^&*-]/, "PASSWORD_SPECIAL"),
    passwordVerify: Validation.string().required(),
});

export const PASSWORD_VALIDATION_TOKEN = new ObjectValidation({
    token: Validation.string().required(),
}).merge(PASSWORD_VALIDATION);

export interface EmailDto {
    email: string;
}

export const EMAIL_VALIDATION = new ObjectValidation({
    email: Validation.string().required().isEmail(),
});

export interface ConfirmRecoveryDto {
    passkey: string;
    password: string;
    token: string;
}

export enum TokenType {
    Refresh = "refresh",
    TwoFA = "twoFA",
    Access = "access",
    OneUse = "oneUse",
    Confirmation = "confirmation",
    Recovery = "recovery",
}

export interface AuthUser {
    email?: string;
    status: AuthUserStatus;
    roles: AuthUserRoles;
    groupsIds?: string[];
}

export interface AuthUserRoles {
    portal: Role;
    npi?: Role;
    factory?: Role;
    controlTower?: Role;
}

export enum AuthUserStatus {
    Invited = "INVITED",
    Confirmed = "CONFIRMED",
    Active = "ACTIVE",
    Suspended = "SUSPENDED",
    Banned = "BANNED",
}
