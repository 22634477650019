import { Icon, KeyIcon, PersonIcon, WarningShieldIcon } from '@sg/icons';
import { Collapse, Content, joinOptionnalStrings, useAuth, useTranslate } from '@sg/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuthData } from '../../models/user.model';
import { requestHandler } from '../../services/request';
import AccountPassword from './components/AccountPassword';
import AccountProfile from './components/AccountProfile';
import AccountRecovery from './components/AccountRecovery';
import AccountSettings from './components/AccountSettings';
import AccountTwoFactor from './components/AccountTwoFactor';
import './index.scss';

export interface AuthDataProps {
    authData: UserAuthData;
    onSubmit: () => void;
}

const Account = () => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [authData, setAuthData] = useState<UserAuthData>();
    const translate = useTranslate();

    const getAuthData = () => requestHandler.get<UserAuthData>('/auth/me', { errorMessage: true })
        .then(setAuthData)
        .catch(() => navigate(-1));

    useEffect(() => {
        getAuthData();
    }, [currentUser]);

    if (!authData) return null;

    return (
        <Content id="account" noPadding scroll>
            <div id="account-header">
                {!!currentUser.avatar && <img src={currentUser.avatar} alt="" />}
            </div>
            <div id="account-content" className="row gap-layout">
                <div className="column grow-1  padding-layout">
                    <div id="account-avatar-name">
                        <div id="account-header-image">
                            {currentUser.avatar
                                ? <img src={currentUser.avatar} alt="" />
                                : <Icon icon={PersonIcon} className="icon" />}
                        </div>
                        <div className="column">
                            <span className="text-xl text-bold">
                                {currentUser.fullName}
                            </span>
                            <span className="text-medium">
                                {joinOptionnalStrings([currentUser.company, currentUser.position])}
                            </span>
                        </div>
                    </div>
                    <AccountProfile />
                </div>
                <div id="account-content-right" className="container column width-30 gap-layout padding-layout">
                    <AccountSettings />
                    <div className="column gap-medium">
                        <h3><Icon icon={KeyIcon} />{translate({ namespace: 'account', key: 'authentication' })}</h3>
                        <span className="text-small">{translate({ namespace: 'account', key: 'authentication_tooltip' })}</span>
                        <div className="column">
                            <Collapse
                                element={
                                    <div className="row grow-1 padding-h-large padding-v-medium align-center">
                                        <span className="text-bold grow-1">{translate('portal.password')}</span>
                                        <span className={`tag background-${authData.password.changeIn > 30 ? 'success' : authData.password.changeIn > 14 ? 'warning' : 'error'}`}>{translate({ namespace: 'account', key: 'change_in' }, { days: authData.password.changeIn })}</span>
                                    </div>
                                }
                            >
                                <AccountPassword onSubmit={getAuthData} />
                            </Collapse>
                            <Collapse
                                element={
                                    <div className="row grow-1 padding-h-large padding-v-medium align-center">
                                        <span className="text-bold grow-1">{translate('account.two_factor')}</span>
                                        <span className="tag background-success">{translate({ namespace: 'account', key: 'enums.two_factor_method.' + authData.twoFactor })}</span>
                                    </div>
                                }
                            >
                                <AccountTwoFactor authData={authData} onSubmit={getAuthData} />
                            </Collapse>
                        </div>
                    </div>
                    <div className="column gap-medium">
                        <h3><Icon icon={WarningShieldIcon} />{translate({ namespace: 'account', key: 'recovery' })}</h3>
                        <span className="text-small">{translate({ namespace: 'account', key: 'recovery_tooltip' })}</span>
                        <Collapse
                            element={
                                <div className="row grow-1 padding-h-large padding-v-medium align-center">
                                    <span className="text-bold grow-1">{translate('account.recovery_address')}</span>
                                    <span className={`tag background-${authData.recovery.status === 'unset' ? 'error' : authData.recovery.status === 'not_verified' ? 'warning' : 'success'}`}>{translate({ namespace: 'account', key: authData.recovery.status })}</span>
                                </div>
                            }
                        >
                            <AccountRecovery authData={authData} onSubmit={getAuthData} />
                        </Collapse>
                    </div>
                </div>
            </div>
        </Content>
    )
}
export default Account;