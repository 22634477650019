import { CheckmarkIcon } from "@sg/icons";
import { Button, FormProvider, GroupsAddRemove, InputConnected, Modal, ObjectValidation, Role, SelectConnected, translateEnum, useForm, useTranslate, Validation } from "@sg/react";
import { useEffect, useMemo } from "react";
import { UserForList } from "..";
import { AuthUser, AuthUserStatus } from "../../../models/auth.model";
import { requestHandler } from "../../../services/request";


export const AUTH_USER_ROLES_VALIDATION = new ObjectValidation({
    portal: Validation.string().required().isEnum(Role),
    npi: Validation.string().isEnum(Role),
    factory: Validation.string().isEnum(Role),
    controlTower: Validation.string().isEnum(Role),
});

export const AUTH_USER_VALIDATION = new ObjectValidation({
    status: Validation.string().required().isEnum(AuthUserStatus),
    roles: AUTH_USER_ROLES_VALIDATION,
    groupsIds: Validation.array(Validation.string())
});

export const AUTH_USER_INVITE_VALIDATION = new ObjectValidation({
    email: Validation.string().required().isEmail(),
    roles: AUTH_USER_ROLES_VALIDATION,
    groupsIds: Validation.array(Validation.string())
});

export interface UserFormModalProps {
    user: Partial<UserForList>;
    onClose: () => void;
}

const UserFormModal = ({ user, onClose }: UserFormModalProps) => {
    const translate = useTranslate();
    const form = useForm<AuthUser>({
        onSubmit: (dto) => (user._id
            ? requestHandler.put(`/auth/user/${user._id}`, dto, { successMessage: true, errorMessage: true, loader: true })
            : requestHandler.post(`/auth/invite`, dto, { successMessage: true, errorMessage: true, loader: true }))
            .then(onClose),
        validation: user._id ? AUTH_USER_VALIDATION : AUTH_USER_INVITE_VALIDATION,
    });

    const roles = useMemo(() => translateEnum(Role, 'users', 'roles', translate), []);
    const status = useMemo(() => translateEnum(AuthUserStatus, 'users', 'status', translate), []);

    useEffect(() => {
        if (user._id) {
            requestHandler.get<AuthUser>(`/auth/user/${user._id}`, { errorMessage: true })
                .then((authUser) => form.reset(authUser))
                .catch(onClose);
        }
    }, []);

    if (!form.formData.status && user._id) return null;

    return (
        <Modal size="medium" onClose={onClose} title={user._id ? `${user.firstName ?? user.email} ${user.lastName ?? ''}` : 'users.create'}>
            <FormProvider {...form} className="flex column gap-large grow-1">
                <div className="row gap-large grow-1">
                    <div className="column gap-large grow-1 width-60">
                        {user._id ? (
                            <SelectConnected id="status" items={status} namespace="entities" />
                        ) : (
                            <InputConnected id="email" label="portal.email" />
                        )}
                        <div className="form-group column">
                            <span>{translate('users.roles')}</span>
                            <div className="form-group row justify-equals">
                                <SelectConnected id="roles.portal" items={roles} label="Portal" />
                                <SelectConnected id="roles.controlTower" items={roles} label="Control tower" />
                            </div>
                            <div className="form-group row justify-equals">
                                <SelectConnected id="roles.factory" items={roles} label="Factory" />
                                <SelectConnected id="roles.npi" items={roles} label="NPI" />
                            </div>
                        </div>
                    </div>
                    <GroupsAddRemove className="grow-1 width-40" selected={form.formData.groupsIds} onChange={(groups) => form.setField('groupsIds', groups)} />
                </div>
                <div className="button-list">
                    <Button label={{ namespace: 'actions', key: 'cancel' }} color="secondary" onClick={onClose} />
                    <Button label={{ namespace: 'actions', key: 'submit' }} icon={CheckmarkIcon} type="submit" />
                </div>
            </FormProvider>
        </Modal >
    )
}
export default UserFormModal;