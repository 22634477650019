import { ButtonSubmit, FormProvider, getCurrentUser, InputConnected, Modal, useForm, User, useTranslate, useUi } from "@sg/react";
import { useDispatch } from "react-redux";
import promoUserDark from '../../../assets/images/promo_user_dark.png';
import promoUserLight from '../../../assets/images/promo_user_light.png';
import { PROFILE_VALIDATION } from "../../../models/user.model";
import { requestHandler } from "../../../services/request";

const WelcomeModal = () => {
    const { darkMode } = useUi();
    const translate = useTranslate();
    const dispatch = useDispatch<any>();
    const form = useForm<User>({
        onSubmit: (user) => requestHandler.patch('/users/profile', user, {
            successMessage: true,
            errorMessage: true,
            loader: true
        }).then(() => dispatch(getCurrentUser()))
            .catch(() => null),
        validation: PROFILE_VALIDATION
    });

    return (
        <Modal size="medium">
            <div className="flex row gap-large">
                <div className="depth-container width-20 shrink-0 padding-none">
                    <img className="height-100" src={darkMode ? promoUserDark : promoUserLight} alt="" style={{ objectFit: 'cover' }} />
                </div>
                <div className="flex column grow-1 gap-large">
                    <div className="flex column grow-1">
                        <h2 className="text-pre">{translate('portal.welcome')}</h2>
                        <span>{translate('portal.welcome_profile')}</span>
                    </div>
                    <FormProvider {...form} className="flex column gap-large">
                        <div className="flex column gap-medium">
                            <div className="flex row gap-medium justify-equals">
                                <InputConnected id="firstName" label={{ key: 'firstName', namespace: 'users' }} />
                                <InputConnected id="lastName" label={{ key: 'lastName', namespace: 'users' }} />
                            </div>
                            <InputConnected id="company" label={{ key: 'company', namespace: 'users' }} />
                            <InputConnected id="field" label={{ key: 'field', namespace: 'users' }} />
                            <div className="flex row gap-medium justify-equals">
                                <InputConnected id="service" label={{ key: 'service', namespace: 'users' }} />
                                <InputConnected id="position" label={{ key: 'position', namespace: 'users' }} />
                            </div>
                        </div>
                        <div className="button-list">
                            <ButtonSubmit type="submit" />
                        </div>
                    </FormProvider>
                </div>
            </div>
        </Modal>
    )
}
export default WelcomeModal;