import { AddIcon, CheckmarkIcon, Icon, InfoIcon } from '@sg/icons';
import { Button, ButtonNavigation, Checkbox, DataListForComponent, Input, keywordToSearchRegex, Modal, SearchInput, Select, stringToSearchField, translateEnum, useFormContext, useManualDataList, User, UserItem, useTranslate, Workspace, WorkspaceRole } from '@sg/react';
import { useCallback, useMemo, useState } from 'react';
import { requestHandler } from '../../../../services/request';

const WorkspaceUsers = () => {
    const form = useFormContext<Workspace>();
    const [addModalMode, setAddModalMode] = useState<string>();
    const data = useManualDataList(form.formData.users, undefined, (e, k) => !!stringToSearchField(String((e as any).user?.fullName)).match(keywordToSearchRegex(k)));
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User>();
    const translate = useTranslate();
    const [emailToInvite, setEmailToInvite] = useState<string>();

    const roles = useMemo(() => translateEnum(WorkspaceRole, 'workspaces', 'roles', translate), []);

    const handleAddUser = useCallback((user: User) => {
        if (!form.formData.users?.some(u => u.userId === user._id)) {
            form.setField('users', [...form.formData.users ?? [], {
                userId: user._id,
                user: user,
                role: WorkspaceRole.User
            }]);
        }
        setAddModalMode(undefined);
    }, [form]);

    const handleSubmit = useCallback(() => {
        if (!selectedUser && !emailToInvite) return;

        if (selectedUser) {
            handleAddUser(selectedUser);
        } else {
            requestHandler.post<User>(`/workspaces/${form.formData._id}/invite`, { email: emailToInvite }, { loader: true, errorMessage: true, successMessage: true })
                .then(handleAddUser)
                .catch(() => null);
        }

    }, [selectedUser, emailToInvite, handleAddUser]);

    const handleSearch = useCallback((keyword?: string) => {
        if (!keyword) {
            setUsers([]);
            return;
        }

        requestHandler.get<User[]>('/users/search', { errorMessage: true, params: { keyword } })
            .then(setUsers)
            .catch(() => setUsers([]));
    }, []);

    return (
        <div className="card">
            <div className="card-header">
                <span className="grow-1">{translate('users.users')}</span>
                <ButtonNavigation label={{ namespace: 'actions', key: 'add' }} icon={AddIcon} onClick={() => setAddModalMode('search')} />
            </div>
            <div className="flex row gap-medium align-center">
                <DataListForComponent {...data}>
                    {data.data?.map(user => (
                        <div key={user.userId} className="list-item">
                            <UserItem className="grow-1" user={user.user ?? user.userId} />
                            <Select id={'user-role-' + user.userId} value={user.role} items={roles} onChange={(role) => form.setField('users', form.formData.users?.map(u => ({ ...u, role: u.userId === user.userId ? role : u.role })))} />
                        </div>
                    ))}
                </DataListForComponent>
            </div>
            {!!addModalMode && (
                <Modal id="user-add-modal" title={{ namespace: 'users', key: 'add' }} size="small" onClose={() => setAddModalMode(undefined)}>
                    <div className="row gap-layout align-center">
                        <Checkbox id="mode-search" value={addModalMode === 'search'} onChange={() => setAddModalMode('search')} />
                        <div className="flex row grow-1 gap-layout align-center">
                            <div className="flex column gap-medium grow-1">
                                <SearchInput id="user-add-modal-search" label={{ namespace: 'users', key: 'search' }} onChange={handleSearch}>
                                    {!!users.length && users.map(u => <div key={u._id} onClick={() => setSelectedUser(u)}><UserItem user={u} /></div>)}
                                </SearchInput>
                                {!!selectedUser && addModalMode === 'search' && (
                                    <div className="flex row align-center">
                                        <UserItem user={selectedUser} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex align-center justify-center">{translate('logic.or')}</div>
                    <div className="row gap-layout align-center">
                        <Checkbox id="mode-search" value={addModalMode === 'invite'} onChange={() => setAddModalMode('invite')} />
                        <div className="flex column gap-small grow-1 align-center">
                            <Input id="user-add-invite" className="grow-1 width-100" label={{ namespace: 'workspaces', key: 'invite' }} onFocus={() => setAddModalMode('invite')} value={emailToInvite} onChange={setEmailToInvite} />
                            <span className="info"><Icon icon={InfoIcon} />{translate('workspaces.invite_tooltip')}</span>
                        </div>
                    </div>
                    <div className="button-list">
                        <div className="button-list button-list-stretch">
                            <Button label={{ namespace: 'actions', key: 'cancel' }} color="secondary" onClick={() => setAddModalMode(undefined)} />
                            <Button label={{ namespace: 'actions', key: 'submit' }} icon={CheckmarkIcon} disabled={(addModalMode === 'invite' && !emailToInvite) || (addModalMode === 'search' && !selectedUser)} onClick={handleSubmit} />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}
export default WorkspaceUsers;