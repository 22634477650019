import { App, APP_ROUTES, AuthenticationWrapper, ProtectedRoute, useConfig } from "@sg/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { requestHandler } from "../services/request";
import Account from "./Account";
import Home from "./Home";
import Portal from "./Portal";
import ConfirmRecovery from "./Portal/ConfirmRecovery";
import ConfirmResend from "./Portal/ConfirmResend";
import ConfirmSignup from "./Portal/ConfirmSignup";
import Login from "./Portal/Login";
import Recovery from "./Portal/Recovery";
import Private from "./Private";
import Users from "./Users";
import Workspaces from "./Workspaces";
import WorkspaceEdit from "./Workspaces/Edit";

const Index = () => {
    const config = useConfig();

    return (
        <App requestHandler={requestHandler}>
            <AuthenticationWrapper>
                <RouterProvider router={createBrowserRouter([
                    {
                        path: "/auth",
                        element: <ProtectedRoute authenticated={false}><Portal /></ProtectedRoute>,
                        children: [
                            {
                                path: "/auth",
                                element: <Login />,
                            },
                            {
                                path: "/auth/recovery",
                                element: <Recovery />,
                            },
                            {
                                path: "/auth/confirm-signup",
                                element: <ConfirmSignup />,
                            },
                            {
                                path: "/auth/confirm-recovery",
                                element: <ConfirmRecovery />,
                            },
                            {
                                path: "/auth/confirm-resend",
                                element: <ConfirmResend />,
                            },
                            {
                                path: "/auth/*",
                                element: <Login />,
                            },
                        ],
                    },
                    ...APP_ROUTES,
                    {
                        path: "/",
                        element: <ProtectedRoute authenticated={true}><Private /></ProtectedRoute>,
                        children: [
                            {
                                path: "/",
                                element: <Home />,
                            },
                            {
                                path: "/account",
                                element: <Account />,
                            },
                            {
                                path: "/users",
                                element: <Users />,
                            },
                            {
                                path: "/workspaces",
                                element: <Workspaces />,
                            },
                            {
                                path: "/workspaces/:_id",
                                element: <WorkspaceEdit />,
                            },
                            {
                                path: "/*",
                                element: <Home />,
                            },
                        ],
                    },
                ], { basename: config.basename })}
                />
            </AuthenticationWrapper>
        </App>
    );
}

export default Index;