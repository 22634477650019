import { Icon, SettingsIcon } from "@sg/icons";
import { getCurrentUser, Select, useAuth, useTranslate } from "@sg/react";
import { useDispatch } from "react-redux";
import { UserSettings } from "../../../models/user.model";
import { requestHandler } from "../../../services/request";

const AccountSettings = () => {
    const translate = useTranslate();
    const { currentUser } = useAuth();
    const dispatch = useDispatch<any>();

    const handleChange = (settings: UserSettings) => requestHandler.patch('/users/settings', settings, { errorMessage: true })
        .then(() => dispatch(getCurrentUser()))
        .catch(() => null)

    return (
        <div className="column gap-medium">
            <h3><Icon icon={SettingsIcon} />{translate({ namespace: 'account', key: 'settings_interface' })}</h3>
            <span className="text-small">{translate({ namespace: 'account', key: 'settings_interface_tooltip' })}</span>
            <div className="form-group column">
                <Select
                    id="settings.language"
                    label={{ namespace: 'ui', key: 'language' }}
                    items={[{ key: 'en', label: 'English' }, { key: 'fr', label: 'Français' }, { key: 'default', label: translate('ui.browser_default') }]}
                    value={currentUser.settings?.language ?? 'default'}
                    onChange={(language) => handleChange({ ...currentUser.settings, language: language === 'default' ? undefined : language })}
                />
                <Select
                    id="settings.theme"
                    label={{ namespace: 'ui', key: 'theme' }}
                    items={[{ key: 'dark', label: translate('ui.dark') }, { key: 'light', label: translate('ui.light') }, { key: 'default', label: translate('ui.browser_default') }]}
                    value={currentUser.settings?.theme ?? 'default'}
                    onChange={(theme) => handleChange({ ...currentUser.settings, theme: theme === 'default' ? undefined : theme })}
                />
            </div>
        </div>
    )
}
export default AccountSettings;