import { AuthStatus, Layout, LayoutNavItem, Role, useAuth } from "@sg/react";
import { Fragment, useMemo } from "react";
import WelcomeModal from "./components/WelcomeModal";

const Private = () => {
    const { currentUser, currentRole, status } = useAuth();

    const items: LayoutNavItem[] = useMemo(() => [
        { url: '/', label: { namespace: 'home', key: 'home' } },
        { url: '/account', label: { namespace: 'account', key: 'account' } },
        ...(currentRole === Role.Admin || currentRole === Role.SuperAdmin ? [
            { url: '/users', label: { namespace: 'users', key: 'users' } }
        ] : []),
        ...(currentRole === Role.Admin || currentRole === Role.SuperAdmin ? [
            { url: '/workspaces', label: { namespace: 'workspaces', key: 'workspaces' } }
        ] : [])
    ], [currentRole]);

    if (!currentUser) return null;

    return (
        <Fragment>
            <Layout appName="Portal" items={items} />
            {status === AuthStatus.Authenticated && !currentUser.firstName && <WelcomeModal />}
        </Fragment>
    )
}
export default Private;