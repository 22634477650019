import { AddIcon, MailIcon } from '@sg/icons';
import { Button, Logo, useUi } from '@sg/react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import promoDark from '../../assets/images/promo_dark.png';
import promoLight from '../../assets/images/promo_light.png';
import './index.scss';

const Portal = () => {
    const { darkMode } = useUi();
    const [isModalVisible, setModalVisibile] = useState<boolean>(false);

    return (
        <div id="portal" className={darkMode ? 'portal-dark' : ''}>
            <div id="portal-content" className="card row grow-0 padding-none">
                <div className="column width-40 padding-xxl">
                    <div id="portal-logo" className="row gap-medium align-center width-100">
                        <Logo className="icon icon-xxl shrink-0" />
                        <span className="text-xxl">Supply-Graph</span>
                    </div>
                    <div id="portal-form" className="flex column gap-large width-100 grow-1">
                        <Outlet />
                    </div>
                </div>
                <div id="portal-promo" className="position-relative grow-1 gap-none padding-none">
                    <img src={darkMode ? promoDark : promoLight} alt="" />
                    <div id="portal-promo-message">
                        <span className="text-pre">All your <i>suppliers</i><br />in one place</span>
                        <div className="flex gap-medium">
                            <Button icon={AddIcon} label={{ namespace: 'portal', key: 'more_info' }} color="secondary" onClick={() => window.open('http://www.supply-graph.io', '_blank')?.focus()} />
                            <Button icon={MailIcon} label={{ namespace: 'portal', key: 'contact_us' }} onClick={() => setModalVisibile(true)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

/* 
<div id="portal-promo" className="flex grow-1">
<div id="portal-promo-message">
    <span className="text-pre">All your <i>Suppliers</i><br />in one place</span>
    <div className="flex gap-medium">
        <Button icon={AddIcon} label={{ namespace: 'portal', key: 'more_info' }} color="secondary" onClick={() => window.open('http://www.supply-graph.io', '_blank')?.focus()} />
        <Button icon={MailIcon} label={{ namespace: 'portal', key: 'contact_us' }} onClick={() => setModalVisibile(true)} />
    </div>
</div>
</div> */

export default Portal;