import { authenticate, Button, FormProvider, PasswordConnected, requestErrorToToast, TokenResponse, useForm, useTranslate } from "@sg/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { ConfirmSignupDto, PASSWORD_VALIDATION_TOKEN } from "../../../models/auth.model";
import { requestHandler } from "../../../services/request";

const ConfirmSignup = () => {
    const translate = useTranslate();
    const [tokenSuccess, setTokenSuccess] = useState<boolean>();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [token, setToken] = useState<string>();
    const [error, setError] = useState<string>();
    const form = useForm<ConfirmSignupDto>({
        onSubmit: (dto) => {
            if (dto.password !== dto.passwordVerify) {
                setError('validation:PASSWORD_MATCH');
                return;
            }

            requestHandler
                .post<TokenResponse>('/auth/confirm-signup/password', dto, { loader: true, withCredentials: true })
                .then((token) => {
                    dispatch(authenticate(token));
                    navigate('/');
                })
                .catch((e) => {
                    const error = requestErrorToToast(e as Error);
                    setError(error.message);
                })
        },
        validation: PASSWORD_VALIDATION_TOKEN
    });

    useEffect(() => {
        if (token) {
            requestHandler.post('/auth/confirm-signup/token', { token })
                .then(() => {
                    form.reset({ token });
                    setTokenSuccess(true);
                })
                .catch(() => setTokenSuccess(false));
        }
    }, [token]);

    useEffect(() => {
        if (searchParams.get('cs_tk')) {
            setToken(searchParams.get('cs_tk')!);
        } else {
            setError('errors:invalid_link');
        }
    }, []);

    if (tokenSuccess === undefined) return null;

    return (
        <Fragment>
            {tokenSuccess ? (
                <Fragment>
                    <div id="portal-title">
                        <span>{translate('portal.signup')}</span>
                        <span>{translate('portal.signup_tooltip')}</span>
                    </div>
                    {!!error && <span className="color-error">{translate(error)}</span>}
                    <FormProvider {...form}>
                        <div className="flex column gap-large">
                            <PasswordConnected id="password" label={{ key: 'password', namespace: 'portal' }} progressBar />
                            <PasswordConnected id="passwordVerify" label={{ key: 'password_verify', namespace: 'portal' }} />
                        </div>
                        <div className="flex column align-center gap-xl padding-v-large">
                            <Button color="accent" className="width-100" label={{ namespace: 'actions', key: 'submit' }} type="submit" />
                        </div>
                    </FormProvider>
                </Fragment>
            ) : (
                <Fragment>
                    <span className="color-error">{translate('custom_errors.SG_EX_AUTH_INVALID_TOKEN')}</span>
                    <Link to="/auth/confirm-resend" className="center" >{translate('portal.resend_confirmation')}</Link>
                </Fragment>
            )}
        </Fragment>
    )
}

export default ConfirmSignup;