import { authenticate, Button, FormProvider, InputConnected, logout, ObjectValidation, PasswordConnected, requestErrorToToast, TokenResponse, TokenType, useAuth, useForm, useTranslate, Validation } from '@sg/react';
import { Fragment, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { LoginDto, LoginTwoFactorDto } from '../../../models/auth.model';
import { requestHandler } from '../../../services/request';

const LOGIN_VALIDATION = new ObjectValidation({
    email: Validation.string().required().isEmail(),
    password: Validation.string().required(),
});

const TWO_FACTOR_VALIDATION = new ObjectValidation({
    code: Validation.string().required().minLength(6).maxLength(6),
    token: Validation.string().required(),
});

interface LoginProps {
    onSubmit: (token: TokenResponse) => void;
}

const LoginCredentials = ({ onSubmit }: LoginProps) => {
    const translate = useTranslate();
    const [error, setError] = useState<string>();

    const form = useForm<LoginDto>({
        onSubmit: (dto) => requestHandler.post<TokenResponse>('/auth/login', dto, { withCredentials: true, loader: true })
            .then(onSubmit)
            .catch((e) => {
                const error = requestErrorToToast(e as Error);
                setError(error.message);
            }),
        validation: LOGIN_VALIDATION
    });

    return (
        <Fragment>
            <div id="portal-title">
                <span>{translate('portal.login_title')}</span>
            </div>
            {!!error && <span className="color-error">{translate(error)}</span>}
            <FormProvider {...form}>
                <div className="flex column gap-large">
                    <InputConnected autoComplete id="email" label={{ key: 'email', namespace: 'portal' }} />
                    <PasswordConnected id="password" label={{ key: 'password', namespace: 'portal' }} />
                </div>
                <div className="flex column align-center gap-xl padding-v-large grow-1">
                    <Button color="accent" className="width-100" label={{ namespace: 'portal', key: 'login' }} type="submit" />
                    <Link to="/auth/recovery" className="center" >{translate('portal.password_lost')}</Link>
                </div>
            </FormProvider>
        </Fragment>
    )
}

const LoginTwoFactor = ({ onSubmit }: LoginProps) => {
    const translate = useTranslate();
    const { token } = useAuth();
    const [error, setError] = useState<string>();
    const dispatch = useDispatch<any>();

    const form = useForm<LoginTwoFactorDto>({
        onSubmit: (dto) => requestHandler.post<TokenResponse>('/auth/login/two-factor', dto, { withCredentials: true, loader: true })
            .then(onSubmit)
            .catch((e) => {
                const error = requestErrorToToast(e as Error);
                setError(error.message);
            }),
        validation: TWO_FACTOR_VALIDATION,
        initial: { token }
    });

    return (
        <Fragment>
            <div id="portal-title">
                <span>{translate('portal.two_factor')}</span>
                <span>{translate('portal.two_factor_tooltip')}</span>
            </div>
            {!!error && <span className="color-error">{translate(error)}</span>}
            <FormProvider {...form}>
                <div className="flex column gap-large">
                    <InputConnected autoComplete id="code" label={{ key: 'two_factor_code', namespace: 'portal' }} />
                </div>
                <div className="flex column align-center gap-xl padding-v-large">
                    <Button color="accent" className="width-100" label={{ namespace: 'portal', key: 'login' }} type="submit" />
                    <span className="link" onClick={() => dispatch(logout())} >{translate('portal.back_to_login')}</span>
                    <Link to="/auth/recovery" className="center" >{translate('portal.password_lost')}</Link>
                </div>
            </FormProvider>
        </Fragment>
    )
}

const Login = () => {
    const { status } = useAuth();
    const dispatch = useDispatch();
    const [URLSearchParams] = useSearchParams();

    const handleSubmit = useCallback((token: TokenResponse) => {
        const redirect = URLSearchParams.get('redirect_url');

        if (token.type === TokenType.Access && redirect) {
            window.location.href = redirect;
        } else {
            dispatch(authenticate(token));
        }
    }, [URLSearchParams]);


    return String(status) === 'credentials'
        ? <LoginTwoFactor onSubmit={handleSubmit} />
        : <LoginCredentials onSubmit={handleSubmit} />;
}
export default Login;