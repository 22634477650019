import { BuildingFactoryIcon, BuildingIcon, GanttChartIcon, Icon } from "@sg/icons";
import { Calendar, Content, Logo, useConfig, useTranslate } from '@sg/react';
import factory from "../../assets/news/factory.jpg";
import greenGraph from "../../assets/news/green_graph.png";
import team from "../../assets/news/team.jpg";
import HomeNewsList from "./components/HomeNewsList";
import './index.scss';

const Home = () => {
    const config = useConfig();
    const translate = useTranslate();

    return (
        <Content id="home" className="height-100">
            <div className="flex row gap-layout grow-1 height-100">
                <div className="flex column gap-layout grow-1 overflow-hidden">
                    <div className="row gap-large">
                        <h3>{translate('home.quick_access')}</h3>
                        <div id="home-quick-access" className="row grow-1 gap-medium">
                            {!!config.env?.controlTowerUrl && (
                                <a href={config.env?.controlTowerURl} target="_blank" rel="noreferrer" className="list-item">
                                    <Logo />
                                    <Icon icon={BuildingIcon} className="icon-large icon-white" />
                                    <span className="text-large color-white">Control tower</span>
                                </a>
                            )}
                            {!!config.env?.factoryUrl && (
                                <a href={config.env?.factoryUrl} target="_blank" rel="noreferrer" className="list-item">
                                    <Logo />
                                    <Icon icon={BuildingFactoryIcon} className="icon-large icon-white" />
                                    <span className="text-large color-white">Factory</span>
                                </a>
                            )}
                            {!!config.env?.npiUrl && (
                                <a href={config.env?.npiUrl} target="_blank" rel="noreferrer" className="list-item">
                                    <Logo />
                                    <Icon icon={GanttChartIcon} className="icon-large icon-white" />
                                    <span className="text-large color-white">NPI</span>
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="column gap-small grow-1 overflow-hidden">
                        <h3>{translate('home.featured')}</h3>
                        <div className="card padding-none gap-xxs grow-1 overflow-hidden row wrap">
                            <div className="home-news height-50 width-100">
                                <img src={factory} alt="" className="width-100" />
                                <div className="home-news-title flex column">
                                    <div>Démarrage de Supply-Graph Factory</div>
                                    <div>La version beta permettant de recréer un plan d'usine et de simuler des trajets est en phase de test.</div>
                                </div>
                            </div>
                            <div className="home-news height-50 width-60">
                                <img src={team} alt="" className="width-100" />
                                <div className="home-news-title flex column">
                                    <div>Nouveau portail de connexion</div>
                                    <div>Centraliser la gestion des utilisateurs et la communication via le portail</div>
                                </div>
                            </div>
                            <div className="home-news height-50 width-30 grow-1">
                                <img src={greenGraph} alt="" className="width-100" />
                                <div className="home-news-title flex column">
                                    <div>Projet Green-Graph</div>
                                    <div>Permettre aux entreprises de visualiser et de réduire leurs impacts environnementaux et sociaux tout en renforçant leur compétitivité</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeNewsList />
                <div className="flex column gap-layout width-25 shrink-0">
                    <div className="card padding-none overflow-hidden">
                        <Calendar />
                    </div>

                </div>
            </div>
        </Content>
    )
}
export default Home;