import { Content, FormControls, FormProvider, InputConnected, ObjectValidation, TextAreaConnected, useForm, useTranslate, Validation, Workspace, WorkspaceRole } from '@sg/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { requestHandler } from '../../../services/request';
import WorkspaceUsers from './components/WorkspaceUsers';

const WORKSPACE_VALIDATION = new ObjectValidation<Workspace>({
    _id: Validation.string().isMongoId(),
    name: Validation.string().required().minLength(3).maxLength(150),
    description: Validation.string().minLength(20).maxLength(250),
    users: Validation.array(new ObjectValidation({
        userId: Validation.string().required().isMongoId(),
        role: Validation.string().required().isEnum(WorkspaceRole)
    })),
    image: Validation.string(),
});

const WorkspaceEdit = () => {
    const translate = useTranslate();
    const { _id } = useParams();
    const navigate = useNavigate();
    const form = useForm<Workspace>({
        onSubmit: (entity) => {
            (entity._id ? requestHandler.put : requestHandler.post)('/workspaces', entity, { loader: true, successMessage: true, errorMessage: true })
                .then(() => navigate(-1))
                .catch(() => null);
        },
        validation: WORKSPACE_VALIDATION,
        disabledWhiteList: true
    });

    useEffect(() => {
        if (_id !== 'create') {
            requestHandler.get<Workspace>(`/workspaces/${_id}/edit`, { loader: true, errorMessage: true })
                .then(form.reset)
                .catch(() => navigate(-1));
        }
    }, [_id]);

    if (_id !== 'create' && _id !== form.formData._id) return null;

    return (
        <Content
            id="workspace-edit"
            title={form.formData._id ? form.formData.name : { namespace: 'actions', key: 'create' }}
            breadcrumbs={[{ label: { namespace: 'workspaces', key: 'workspaces' }, url: '/workspaces' }]}
            className="overflow-y-auto"
        >
            <FormProvider {...form} className="flex column gap-layout">
                <FormControls />
                <div className="flex row gap-layout">
                    <div className="flex column gap-layout width-70">
                        <div className="card">
                            <div className="card-header">{translate('entities.identification')}</div>
                            <div className="flex row gap-large grow-1">
                                <div className="form-group column grow-1">
                                    <InputConnected id="name" namespace="entities" />
                                    <TextAreaConnected rows={5} id="description" namespace="entities" />
                                </div>
                                <div className="soft-container width-30 color-grey text-small">
                                    {translate('workspaces.identification_tooltip')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex column gap-layout width-30">
                        <WorkspaceUsers />
                    </div>
                </div>
            </FormProvider>
        </Content>
    )
}
export default WorkspaceEdit;