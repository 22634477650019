import { LinkIcon } from "@sg/icons";
import { Card, FormControls, FormProvider, getCurrentUser, ImageFieldConnected, InputConnected, useAuth, useForm, User, useTranslate, Validation } from "@sg/react";
import { useDispatch } from "react-redux";
import { PROFILE_VALIDATION } from "../../../models/user.model";
import { requestHandler } from "../../../services/request";


const AccountProfile = () => {
    const { currentUser } = useAuth();
    const dispatch = useDispatch<any>();
    const translate = useTranslate();
    const form = useForm<User>({
        onSubmit: (profile) => requestHandler.patch('/users/profile', profile, { loader: true, errorMessage: true }).then(() => dispatch(getCurrentUser())),
        validation: PROFILE_VALIDATION.merge({
            contact: {
                phoneOffice: Validation.string().minLength(6).maxLength(20),
                phoneMobile: Validation.string().minLength(6).maxLength(20),
                email: Validation.string().isEmail(),
                link1: Validation.string().isUrl(),
                link2: Validation.string().isUrl(),
                link3: Validation.string().isUrl(),
            }
        }),
        initial: { ...currentUser }
    });

    return (
        <FormProvider {...form} className="flex column grow-1">
            <FormControls />
            <div className="block-inline row gap-medium width-100">
                <div className="column gap-xs width-30">
                    <span className="text-bold">{translate({ namespace: 'account', key: 'informations' })}</span>
                    <span className="text-small">{translate({ namespace: 'account', key: 'informations_tooltip' })}</span>
                </div>
                <Card className="grow-1">
                    <div className="form-group justify-equals">
                        <InputConnected id="firstName" label={{ key: 'firstName', namespace: 'users' }} />
                        <InputConnected id="lastName" label={{ key: 'lastName', namespace: 'users' }} />
                    </div>
                    <InputConnected id="company" label={{ key: 'company', namespace: 'users' }} />
                    <InputConnected id="field" label={{ key: 'field', namespace: 'users' }} />
                    <div className="form-group justify-equals">
                        <InputConnected id="service" label={{ key: 'service', namespace: 'users' }} />
                        <InputConnected id="position" label={{ key: 'position', namespace: 'users' }} />
                    </div>
                </Card>
            </div>
            <div className="block-inline row gap-medium width-100">
                <div className="column gap-xs width-30">
                    <span className="text-bold">{translate({ namespace: 'account', key: 'avatar' })}</span>
                    <span className="text-small">{translate({ namespace: 'account', key: 'avatar_tooltip' })}</span>
                </div>
                <Card className="grow-1">
                    <ImageFieldConnected id="avatar" className="grow-1" requestHandler={requestHandler} />
                </Card>
            </div>
            <div className="block-inline row gap-medium width-100">
                <div className="column gap-xs width-30">
                    <span className="text-bold">{translate({ namespace: 'account', key: 'contact' })}</span>
                    <span className="text-small">{translate({ namespace: 'account', key: 'contact_tooltip' })}</span>
                </div>
                <Card className="grow-1">
                    <div className="form-group row justify-equals">
                        <InputConnected id="contact.phoneOffice" label={{ namespace: 'users', key: 'phoneOffice' }} />
                        <InputConnected id="contact.phoneMobile" label={{ namespace: 'users', key: 'phoneMobile' }} />
                    </div>
                    <InputConnected id="contact.email" label={{ namespace: 'users', key: 'email' }} />
                </Card>
            </div>
            <div className="block-inline row gap-medium width-100">
                <div className="column gap-xs width-30">
                    <span className="text-bold">{translate({ namespace: 'account', key: 'links' })}</span>
                    <span className="text-small">{translate({ namespace: 'account', key: 'links_tooltip' })}</span>
                </div>
                <Card className="grow-1"><div className="flex column gap-xs grow-1">
                    <InputConnected icon={LinkIcon} id="contact.link1" />
                    <InputConnected icon={LinkIcon} id="contact.link2" />
                    <InputConnected icon={LinkIcon} id="contact.link3" />
                </div>
                </Card>
            </div>
        </FormProvider>

    )
}
export default AccountProfile;