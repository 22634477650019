import { CheckmarkCircleIcon, Icon } from '@sg/icons';
import { ButtonSubmit, FormProvider, InputConnected, requestErrorToToast, useForm, useTranslate, withVerifyIdentity } from '@sg/react';
import { useState } from 'react';
import { AuthDataProps } from '..';
import { EMAIL_VALIDATION, NewPassword } from '../../../models/auth.model';
import { requestHandler } from '../../../services/request';


const AccountRecovery = ({ authData, onSubmit }: AuthDataProps) => {
    const translate = useTranslate();
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<boolean>(false);
    const form = useForm<NewPassword>({
        onSubmit: (dto) => requestHandler
            .patch('/auth/recovery-email', dto, { loader: true })
            .then(() => {
                setSuccess(true);
                onSubmit();
            })
            .catch((e) => {
                const error = requestErrorToToast(e as Error);
                setError(error.message);
            }),
        validation: EMAIL_VALIDATION
    });

    if (success) return (
        <div className="column gap-medium padding-layout align-center justify-center">
            <Icon icon={CheckmarkCircleIcon} className="icon-success icon-xl" />
            <span>{translate('account.recovery_address_set')}</span>
        </div>
    )

    return (
        <FormProvider {...form} className="soft-container form-group column">
            <span className="color-warning">{translate('ui.degraded_feature')}</span>
            {!!error && <span className="color-error">{translate(error)}</span>}
            <InputConnected id="email" label="account.recovery_address" />
            <div className="button-list">
                <ButtonSubmit type="submit" />
            </div>
        </FormProvider>
    )
}

export default withVerifyIdentity(AccountRecovery);